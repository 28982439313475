import { getGeotabAuth } from '@/helpers/get-geotab-auth';
import { AuthModule } from '@/store/auth';
import { tryRequest } from '.';
import { baseURL } from './accidentplan-api';
import {
  GeotabAPIDefaultCreateDTO,
  GeotabSyncUsersResponse,
  GetGeotabConfigResponse,
  GetGeotabGroupsResponse,
  UpdateGeotabConfigDTO,
} from './dto/geotab.dto';
import { CreateSingleOrgDTO } from './dto/org';
// eslint-disable-next-line
const Geotab = require('mg-api-js');

let geotabApi: any = null;

export async function getGeotabApi() {
  if (geotabApi) return geotabApi;

  geotabApi = new Geotab(await getGeotabAuth(), {
    newCredentialStore: new Map(),
  });
  // console.log('do we reach this?');

  return geotabApi;
}

// gets as much information as possible for a create org DTO from Geotab system settings
export async function getGeotabDefaultCreateDTO(): Promise<CreateSingleOrgDTO> {
  const geotabApi = await getGeotabApi();

  const [systemSettings]: GeotabAPIDefaultCreateDTO[] = await geotabApi.call(
    'Get',
    {
      typeName: 'SystemSettings',
    },
  );

  const createOrgDTO: CreateSingleOrgDTO = {
    name: systemSettings.companyName,
    address: systemSettings.companyAddress,
    country: systemSettings.countryCode,
    main_phone: systemSettings.phoneNumber,
  };

  return createOrgDTO;
}

export async function getGeotabConfig(orgId: number) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetGeotabConfigResponse>(
      fetch(`${baseURL}/integrations/geotab/config?org_id=${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.config;
}

export async function updateGeotabConfig(
  orgId: number,
  credConfig: UpdateGeotabConfigDTO,
) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetGeotabConfigResponse>(
      fetch(`${baseURL}/integrations/geotab/config?org_id=${orgId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(credConfig),
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.config;
}

export async function getGeotabGroups(orgId: number) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetGeotabGroupsResponse>(
      fetch(
        `${baseURL}/integrations/geotab/config/securitygroups?org_id=${orgId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.groups;
}

export async function syncGeotabUsers(orgId: number) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GeotabSyncUsersResponse>(
      fetch(`${baseURL}/integrations/geotab/config/users/sync?orgId=${orgId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response;
}
